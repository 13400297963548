import styled from 'styled-components';
import { Tablet } from '../../../atoms/_Variable/var';

export const CUSTOM_TABLET = 900;

export const Wrapper = styled.section`
	background-color: #f9fbff;
	margin-top: 40px;
	padding-top: 9rem;
	padding-bottom: 17rem;
	@media only screen and (max-width: ${CUSTOM_TABLET}px) {
		padding-bottom: 16.438rem;
	}

	.wrapperPartnership {
		display: flex;
		@media only screen and (max-width: ${CUSTOM_TABLET}px) {
			flex-direction: column-reverse;
		}
		&_desc {
			flex: 1;
			.subTitle {
				margin-top: 1rem;
				margin-bottom: 2.375rem;
				max-width: 551px;
			}
			ul {
				list-style: none;
				li {
					display: flex;
					align-items: center;
					.pDefault {
						margin-left: 0.5rem;
					}
					picture,
					img {
						max-width: 40px;
					}
					&:not(:first-child) {
						margin-top: 0.882rem;
					}
				}
			}
			.btnWrapper {
				margin-top: 2rem;
				display: flex;
				.btnPrimaryDefault {
					padding: 0.625rem 2rem !important;
				}
			}
		}

		&__img {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			img,
			picture {
				width: 100%;
				max-width: 497px;
				z-index: 999;
			}

			@media only screen and (max-width: ${CUSTOM_TABLET}px) {
				margin-bottom: 3.75rem;
			}
		}
	}

	.quarterCircle {
		background-image: url(https://seiturju.sirv.com/Chatbiz/v4/quarter-circle-big-1.svg);
		width: 375px;
		height: 447px;
		transform: scale(1);
		position: absolute;
		top: 82px;
		right: 0;
		background-repeat: no-repeat;
		z-index: 1;

		@media only screen and (max-width: ${Tablet}) {
			transform: scale(0.6);
			top: 60px;
			opacity: 0.25;
		}
	}
`;

export default Wrapper;
