import React, { useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import H1 from '../../../atoms/Label/Headers/H1/clear';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import { ButtonPrimaryLink } from '../../../atoms/Button/Primary';
import { SVGRender } from '../../../../helper/image';

import { Wrapper } from './style';

const BannerPartnership = () => {
	const intl = useIntl();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper className="banner-partnership">
			<div className="container">
				<div className="wrapperPartnership">
					<div className="wrapperPartnership_desc">
						<H1 className="h1Default title">{intl.formatMessage({ id: `join_partnership.title` })}</H1>
						<Paragraph className="pDefault subTitle">
							{intl.formatMessage({ id: `join_partnership.sub` })}
						</Paragraph>
						<ul>
							<li>
								{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/ico-green-tick.svg', 'ico-partnership')}
								<Paragraph>{intl.formatMessage({ id: `join_partnership.list_1` })}</Paragraph>
							</li>
							<li>
								{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/ico-green-tick.svg', 'ico-partnership')}
								<Paragraph>{intl.formatMessage({ id: `join_partnership.list_2` })}</Paragraph>
							</li>
							<li>
								{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/ico-green-tick.svg', 'ico-partnership')}
								<Paragraph>{intl.formatMessage({ id: `join_partnership.list_3` })}</Paragraph>
							</li>
						</ul>
						<div className="btnWrapper">
							<ButtonPrimaryLink href="https://go.chatbiz.id/partnership-signup" target="__blank">
								{intl.formatMessage({ id: `join_partnership.list_3` })}
							</ButtonPrimaryLink>
						</div>
					</div>
					<div className="wrapperPartnership__img">
						{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/banner-collabs.svg')}
					</div>
				</div>
			</div>
			<div className="quarterCircle" />
		</Wrapper>
	);
};

export default BannerPartnership;
